<script setup>
    import {defineProps,useAttrs,ref } from 'vue';
    import ODropdown from 'o365.vue.components.DropDown.vue';

    const props = defineProps({
        columnName:String,
        filterObject:null,
        searchOnEnter:Boolean,
        inputClassName:String,
        hidePlaceholder:Boolean,
    });

    const popperOptions = [
    {
        name: 'offset',
            options: {
                offset: [-1, -1],
            },
        },
    ]

    const id = "componenet_"+new Date();


    let item = props.filterObject.getItem(props.columnName);
    const attrs = useAttrs();


    const clear =() =>{
        item = props.filterObject.getItem(props.columnName)
        item.resetItem();

        doFilter();
    }

    const applySearchEnter = (evt) => {

        doFilter();
    }


    const doFilter = () =>{
        props.filterObject.apply();
        if(props.searchOnEnter){
            
        }
    }
    
</script>
<script>
    import OFilterDropdown from 'o365.vue.components.FilterDropdown.vue';
    export default {
        components: { OFilterDropdown }
    }
</script>
<template>

    <div class="position-relative"  ref="searchContainer" :key="item.updated">
        <div class="form-floating">
            <input disabled 
            :class="inputClassName" 
            class="text-truncate" 
            :value="item.disabledTitle" 
            :title="item.label" 
            v-if="item.disabledTitle ">
            <input class="text-truncate"
             v-if="!item.disabledTitle  && !item.isDate" 
                :class="inputClassName"
                :title="item.label"
                :readonly="item.isBit"
                @keyup.enter="applySearchEnter"
                :placeholder="hidePlaceholder?'':item.caption"
                type="text"
                autocomplete="nope"
                v-model="item.selectedValue"
                :id="'floatingInput'+id" >
            <label for="'floatingInput'+id">{{item.title}} {{item.operatorTitle}}</label>
        </div>
    
        <button v-if="item.selectedValue !== null" @click="clear" :class="item.filterDropdown?'pe-4':'pe-1'" class="btn btn-link position-absolute close clear-btn text-decoration-none pt-0 end-0 top-0" style="margin-top:2px;">
        &times;
    </button>
        <o-dropdown placement="bottom-end" v-if="item.filterDropdown" :popperOptions="popperOptions">
            <template #default="scope">
                <button class="btn btn-link btn-sm position-absolute px-0 top-0" :ref="scope.target" @click="scope.open" style="right:5px;z-index:5;">
            <i class="bi bi-funnel-fill"></i>
        </button>
            </template>

            <template #dropdown="scope">
                <div class="card shadow rounded-0 o365-filter-dropdown bg-light-subtle" :ref="scope.container">
                    <div class="p-2"
                        :style="[{'width':Math.max(400,$refs.searchContainer.clientWidth-2)+'px'}]">

                        <component :is="item.filterDropdown" :filterItem="item" :filterObject="filterObject"
                            :dropdown="scope">
                        </component>
                    </div>
                </div>
            </template>
        </o-dropdown>
    </div>

    
</template>